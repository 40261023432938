import React from 'react'
import { Helmet } from 'react-helmet'
import { Link } from 'gatsby'


const socialStyles = {
    borderRadius: '100px',
    backgroundColor: '#24486a',

    padding: 0,
    width: '38px',
    height: '38px'
};

const socialStylesYouTube = {
  borderRadius: '100px',
  backgroundColor: '#24486a',

  padding: 0,
  height: '38px',
  width:'42px'
};

const Footer = ({menuLinks}) => { 
  
 const menu = menuLinks.filter(l => l.name !== 'Login')
  return(
  <footer id="footer">


   <div className="footer-menu">
      {menu.map( link => <Link key={link.link} to={link.link}>
                        {link.name}
                      </Link>)}

      <div className="footer-contact">
        <p>
          <a href="tel:8888218084" className="icon solid alt fa-phone">
            <span className="label">phone</span> 
            888-821-8084
          </a> 
        </p>
          
        <p>
          <a href="/" className="icon solid alt fa-envelope">
            <span className="label">Email</span>
            info@bullsimarketing
          </a> 
        </p>
        <p>
          <a href="/" className="icon solid alt fa-map-marker-alt">
              <span className="label">location</span>
              1201 6th Ave W Ste #100 #A302
          Bradenton, FL 34205
          </a>
        </p>
      </div>
  </div>

    <ul className="icons">
      
      <li style={socialStyles}>
        <a href="https://twitter.com/bulls_marketing" className="icon brands alt fa-twitter">
          <span className="label">Twitter</span>
        </a>
      </li>
      <li style={socialStyles}>
        <a href="https://www.linkedin.com/in/david-maddox-41120b20b/" className="icon brands alt fa-linkedin-in">
          <span className="label">LinkedIn</span>
        </a>
      </li>
      <li style={socialStyles}>
        <a href="https://www.facebook.com/bullsimarketing" className="icon brands alt fa-facebook-f">
          <span className="label">Facebook</span>
        </a>
      </li>
      <li style={socialStyles}>
        <a href="https://www.instagram.com/bulls_i_marketing/" className="icon brands alt fa-instagram">
          <span className="label">Instagram</span>
        </a>
      </li>
      <li style={socialStyles}>
        <a href="https://www.instagram.com/bulls_i_marketing/" className="icon brands alt fa-pinterest">
          <span className="label">Pinterest</span>
        </a>
      </li>
      <li style={socialStylesYouTube}>
        <a href="https://www.youtube.com/channel/UCszHcq8meUsFh9FJQoJ7GiA" className="icon brands alt fa-youtube">
          <span className="label">YouTube</span>
        </a>
      </li>  
    </ul>

    <ul className="copyright">    
      <li>&copy; Bulls i Marketing. All rights reserved.</li>
    </ul>

  </footer>
)}

export default React.memo(Footer)

/*

<Helmet>
<script>
{ //`
if(typeof window !== 'undefined') {

  window.setTimeout(   () => { window.HelpChatWidget.init( { agent: "gNtDCs9vbH0AqITpS4gZ", company: "YZzn7QTYBqK3e1e2", options: { "position": "right", "selector": "#chatBot"}}) }
  , 2000)

}
//`
}

</script>

</Helmet>
*/